          <template>
  <div class="makes"  v-if="typeof dashboardelementindex === 'undefined'">
  <v-dialog v-model="importresultsdialog" max-width="800px">
        <v-card dark height="100%" width ="100%" class="center secondary">
          <v-img src="@/assets/RABaseBG.jpeg">
          <v-card-title>
            Import Result<v-spacer></v-spacer><v-icon @click="CloseImportresultsdialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Successful Imports ({{SuccessfulImportsArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in SuccessfulImportsArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Partial Failures ({{PartialFailuresArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in PartialFailuresArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer><v-btn href="/DataImports" target="_blank" color="green" dark>View Import Record</v-btn>
          
          </v-card-actions>
          </v-img>
        </v-card>
      </v-dialog>

      <v-dialog v-model="ImportingProgressDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Importing Records</h2><br>
                Now Importing - 
                          <div class="red--text">
                          <p>{{Importcount}}</p>
                          </div>
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
                  <v-layout row class="justify-center mx-3 my-3">
                      <progress id="importprogressbar">
                      </progress>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
    
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome!Make.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
     
           <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>
    
     <v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RASolidA.jpeg"
        v-if="!$route.params.id  && AppisDarkMode  && !PageView"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="stdparallaxgradient text-center" cols="12">
    <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Makes</span>
    </v-col>
  </v-row>
    </v-parallax>
    <v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RASolidA-Light.jpeg"
        v-if="!$route.params.id  && !AppisDarkMode || PageView"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="stdparallaxgradient text-center" cols="12">
    <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large></v-icon>Makes</span>
    </v-col>
  </v-row>
    </v-parallax>

    <v-layout class="justify-center" style="padding-top: 70px"  id="makesskeleton">
      <v-flex lg10 md10> 
    <v-sheet
        color="secondary"
        class="mx-6 my-3"
        style="margin-top:150px;"
      >
      <div class="hidden-sm-and-down">
            <h1 class="Header white--text">Makes</h1>
        </div>
        <v-skeleton-loader
          class="mx-auto"
        
          type="table-thead"
        ></v-skeleton-loader>
        <v-skeleton-loader v-for="n in 5" :key="n.itemObjKey"
          class="mx-auto"
          
          type="table-row"
        ></v-skeleton-loader>
      </v-sheet>
  </v-flex>
 </v-layout>

        
    <v-layout class="justify-center"  :style="CollectionPadding">
      <v-flex    lg10 md10>
     <div id="makesdata" style="display:none">
    
      
          
              <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-img
            width="500"
            src="@/assets/RABaseBG-Soft.png"
            ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
              <v-card-title class="mediumoverline white--text">
                {{ formTitle }}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-factory</v-icon>
              </v-card-title>
              <v-card-subtitle class="overline white--text">
              <v-icon  class="actionicon" small :color="formSubTitleIconColor">{{formSubTitleIcon}}</v-icon> {{formSubTitle}}
            </v-card-subtitle></div>
                <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field v-model="editedItem.Name" color="red" :rules="[rules.required]" id="Name" label="* Name"></v-text-field>
                        
                        
                        
                        
                        
                        
                          <v-combobox
                            class="FormFields"
                            v-model="editedItem.Status"
                            :items="StatusOptions"
                            hide-selected
                            item-text="Name"
                            label="Status Options"
                            small-chips
                            solo 
                      
                            flat
                          ></v-combobox>
                          <v-combobox
                            v-if="editedItem.Status && editedItem.Status.Options"
                            class="FormFields"
                            v-model="editedItem[editedItem.Status.Options[0].LevelFieldName]"
                            :items="editedItem.Status.Options"
                            hide-selected
                            item-text="Name"
                            :label="editedItem.Status.Options[0].LevelFieldName"
                            small-chips
                            solo 
                      
                            flat></v-combobox>
                        
                      </v-col>
                    </v-row>       
                </v-card-text>
                <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="close()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="save()">Save</v-btn>
              </v-card-actions>
                 </v-img>
              </v-card>
            </v-dialog>
  
        <v-dialog v-model="importdialog" max-width="500px">
               <v-card>
                 <v-img
                    width="500"
                    src="@/assets/RABaseBG-Soft.png"
                    ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                      <v-card-title class="mediumoverline white--text">
                        Import Makes<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-factory</v-icon>
                      </v-card-title>
                      <v-card-subtitle class="overline white--text">
                      <v-icon  class="actionicon" small color="green">mdi-database-import</v-icon> Import Makes here
                    </v-card-subtitle>
                    <v-card-subtitle class="overline white--text">
                     <v-icon  class="actionicon" @click="DownloadMakesImportTemplate()" small color="white">mdi-download</v-icon> Download Makes Template
                    </v-card-subtitle></div>
                 <v-card-text>
                     <v-row>
                       <v-col cols="12" sm="12" md="12" lg="12">
                         <input v-if="!ImportFile"
                                   type="file"
                                   @change="onImportfileSelected($event)"
                                   ref="ImportFileinputter"
                                   id="fileUpload"
                                   >
                         <v-text-field class="FormFields" v-model="Import.Name" label="Import File Name"></v-text-field>
                         
                         
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions class="white documenttypeshadowinvert">                  
                  <v-btn color="orange" dark class="overline" @click="closeImport()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn color="green" dark  class="overline" @click="saveImport()">Save</v-btn>
                </v-card-actions>
                 </v-img>
               </v-card>
             </v-dialog>

    
          <v-dialog v-model="exportdialog" max-width="500">
      <v-card>
        <v-img
          width="500"
          src="@/assets/RABaseBG-Soft.png"
          ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
            <v-card-title class="mediumoverline white--text">
              Export Makes<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">mdi-factory</v-icon>
            </v-card-title>
            <v-card-subtitle class="overline white--text">
            <v-icon  class="actionicon" small color="green">mdi-database-export</v-icon> Export Makes here
          </v-card-subtitle>
          </div>
        <v-card-text class="FormFields">
          This will export all data in your curent view, in .CSV Format. Choose below whether to Export just for viewing, or to Export for importing of Re-Importing the sheet
          to perform Bulk updates to the Makes Records:
          <v-list class="transparent">
            <v-list-item>
              <v-btn @click="ExportData()" class="overline blue--text" text>Export for View</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="ExportDataforReimport()" class="overline blue--text" text>Export for Reimport</v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions  class="white documenttypeshadowinvert">
          <v-btn color="orange" dark class="overline" @click="closeExport()">Cancel</v-btn>
          </v-card-actions>           
        </v-img>
      </v-card>
    </v-dialog>



        
  <v-toolbar v-if="MakesArray" elevation="6" dark dense rounded src="@/assets/RABaseBG.jpeg" style="margin-bottom:30px;">
          <v-layout col class="justify-start">
            <h3 class="my-8">Data Filters</h3><v-spacer></v-spacer>
          <div width="100px"><br>
            <v-autocomplete class="mx-5"
            :items="StatusOptions"
            v-model="Statusfilter"
            @change="Status_Reasonfilter = ''"
            item-text="Name"
            label="Status"
            autowidth
            return-object
            chips
            flat
            />
            </div>
          <div width="100px" v-if="Statusfilter"><br>
            <v-autocomplete class="mx-5"
            :items="Statusfilter.Options"
            v-model="Status_Reasonfilter"
            item-text="Name"
            label="Status_Reason"
            autowidth
            return-object
            chips
            flat
            />
            </div>
            <v-btn outlined fab small class="elevation-6"  style="margin-top:25px;" >
              <v-icon @click="ResetFilters()">mdi-close</v-icon>
            </v-btn>
            </v-layout>
          </v-toolbar>
              
    <v-layout row class="justify-center">
      <v-card class="transparent" flat width="90%">
        <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12" style="margin-bottom:-40px;" color="primary white--text">
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white">mdi-factory</v-icon>Makes</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-sequential</v-icon>
          <v-icon  class="actionicon mx-1" color="white">mdi-view-grid</v-icon>
         
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark>
              <v-icon  v-on="on" v-if="CanCreate || userIsAdmin || HasCharts || HasProcesses" dark>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
              <v-list-item class="listoutline"  @click="dialog = !dialog"  v-if="CanCreate || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    New
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="importdialog = !importdialog" v-if="UserRecord.CanImport || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    Import
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="grey">mdi-database-import</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item  class="listoutline" @click="ActivateExportDialog(MakesArray,headers)"  v-if="UserRecord && UserRecord.CanExport || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    Export
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="grey">mdi-database-export</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-card>
    </v-layout>
        <v-data-table
      :page.sync="page"
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :dark="AppisDarkMode"
      :headers="ComputedHeaders"
      :items="MakesFilteredandSearched"
      class="elevation-1"
      id="makesdatatable"
    >
      <template v-slot:top>
        <div style="padding-top:40px;"/>
      </template>
    <template v-slot:[`item.Name`]="{ item }">
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text"><v-icon small v-if="item && CanGet">mdi-factory</v-icon></v-btn> {{item.Name}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
      <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item && CanEdit">
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item && CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
      <template v-slot:footer>
      <v-card outlined>
        <v-pagination
        :total-visible="10"
          v-model="page"
          circle
          :length="pageCount"
        ></v-pagination>
        <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="itemsPerPage = parseInt($event, 10)"
        ></v-text-field>
        </v-card>
      </template>

    </v-data-table>

    
    
    </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'


export default {
  props: ['UsersArray','dashboardelementindex','IntranetView','RelatedObj','PageFilter','PageView','RelatedParentObj','RelatedGroupFilter','DashboardView','DashboardArray','CompSize','AllowsHistoric','ReferenceView','DocTableCall','RenderComp','AppisDarkMode'],
  components: {
  },
  data() {
    return {
  UserRecord: '',
  page: 1,
  pageCount: 0,
  itemsPerPage: 10,
  ParentFilterField: '',
  ExportDataArray: [],
  ExportHeaders: [],
  exportdialog: false,
  
  
  StatusOptions: [{
  "ID": 1000001,
  "LevelFieldName": "Status",
  "HasDeepHierarchy": true,
  "Name": "Active",
  "Level": {
    "children": [
      {
        "FieldName": "Status_Reason",
        "children": [],
        "id": 2,
        "name": "Status Reason"
      }
    ],
    "id": 1,
    "FieldName": "Status",
    "name": "Status"
  },
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Name": "Open",
      "Level": {
        "name": "Status Reason",
        "children": [],
        "id": 2,
        "FieldName": "Status_Reason"
      },
      "ID": 1000003
    }
  ]
},{
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Level": {
        "id": 2,
        "FieldName": "Status_Reason",
        "children": [],
        "name": "Status Reason"
      },
      "Name": "Closed",
      "ID": 1000004
    }
  ],
  "ID": 1000002,
  "Name": "InActive",
  "Level": {
    "children": [
      {
        "id": 2,
        "FieldName": "Status_Reason",
        "name": "Status Reason",
        "children": []
      }
    ],
    "FieldName": "Status",
    "id": 1,
    "name": "Status"
  },
  "LevelFieldName": "Status",
  "HasDeepHierarchy": true
}],
  Statusfilter: {
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "ID": 1000001,
  "Name": "Active",
  "Level": {
    "name": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "children": [],
        "id": 2,
        "name": "Status Reason"
      }
    ],
    "id": 1,
    "FieldName": "Status"
  },
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Name": "Open",
      "ID": 1000003,
      "Level": {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    }
  ]
},
  Status_Reasonfilter: '',
  dialog: false,
  initialize: '',
  search: '',
  BusinessUnitsArray: [],
  headers: [
    { text: 'Name', value: 'Name',class: "overline"},
    
    { text: 'Status', value: 'Status.Name',class: "overline"},
    { text: 'Status_Reason', value: 'Status_Reason.Name',class: "overline"},
    { text: 'Actions', value: 'actions', sortable: false ,class: "overline"},
  ],
  snackbar: false,
  Reimport: false,
  importdialog: false,
  importsnackbar: false,
  ImportHeaders: [],
  ImportingProgressDialog: false,
  Importcount: '',
  importresultsdialog: false,
  NewImportsArray: [],
  PartialFailuresArray: [],
  SuccessfulImportsArray: [],
  CSVResult: [],
  Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Makes', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
  ImportFile: '',
  EditedMakesnackbar: false,
  MakeEditdialog: false,
  newMakedialog: false,
  MakesArray: [],
  rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
  editedIndex: -1,
  defaultItem: {
    id: '',
      Name: '',
      Status: {
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "ID": 1000001,
  "Name": "Active",
  "Level": {
    "name": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "children": [],
        "id": 2,
        "name": "Status Reason"
      }
    ],
    "id": 1,
    "FieldName": "Status"
  },
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Name": "Open",
      "ID": 1000003,
      "Level": {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    }
  ]
},
      Status_Reason: {
  "Name": "Open",
  "ID": 1000003,
  "Level": {
    "children": [],
    "FieldName": "Status_Reason",
    "name": "Status Reason",
    "id": 2
  },
  "LevelFieldName": "Status_Reason"
},
  },
  editedItem: {
    id: '',
      Name: '',
      Status: {
  "HasDeepHierarchy": true,
  "LevelFieldName": "Status",
  "ID": 1000001,
  "Name": "Active",
  "Level": {
    "name": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "children": [],
        "id": 2,
        "name": "Status Reason"
      }
    ],
    "id": 1,
    "FieldName": "Status"
  },
  "Options": [
    {
      "LevelFieldName": "Status_Reason",
      "Name": "Open",
      "ID": 1000003,
      "Level": {
        "name": "Status Reason",
        "id": 2,
        "FieldName": "Status_Reason",
        "children": []
      }
    }
  ]
},
      Status_Reason: {
  "Name": "Open",
  "ID": 1000003,
  "Level": {
    "children": [],
    "FieldName": "Status_Reason",
    "name": "Status Reason",
    "id": 2
  },
  "LevelFieldName": "Status_Reason"
},
  },
  UndefinedMandatoryFields: [],
  CollectionRef: db.collection('makes'),
  CustomCreateRoles: [
  "Office Administrator"
],
  CustomUpdateRoles: [
  "Office Administrator"
],
    
    }
  },

  mounted() {
    if(this.CompSize){
    this.$vuetify.breakpoint.width = this.CompSize
    }
  },
  
  created(){
    if(this.PageView){
      this.itemsPerPage = 5
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if(this.CompSize){
      this.$vuetify.breakpoint.width = this.CompSize
      console.log(this.$vuetify.breakpoint)
    }
    
  if(typeof this.dashboardelementindex === 'undefined'){
setTimeout(() => {
  document.getElementById('makesskeleton').style.display = 'none'
  document.getElementById('makesdata').style.display = 'block'      
  },1500)
  }


window.onload = setTimeout(() => {this.CheckRouting()},1500)



  },
  computed: {
        ComputedHeaders(){
    return this.headers.filter(header => {
      return !header.Identifier
    })
  },
    MakesQuery(){
    return this.$store.state.MakesQuery 
    },
  CanGet(){
    return this.$store.state.MakesCanView
  },
  CanList(){
    return this.$store.state.MakesCanList
  },
  CanEdit(){
    return this.$store.state.MakesCanEdit
  },
  CanDelete(){
    return this.$store.state.MakesCanDelete
  },
  CanCreate(){
    return this.$store.state.MakesCanCreate
  },
    
    CollectionPadding(){
    if(!this.$route.params.id && !this.PageView){
      return 'padding-top: 70px'
    }
    else if(this.PageView){
    return 'padding: 20px'
    }
    else{
      return ''
    }
  },
    MakesStore() {
      return this.$store.getters.getMakesArray
    },
    
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userLoggedIn () {
    return this.$store.getters.user
  },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  UsersStore(){
    return this.UsersArray
  },
    FinalDashboardArray(){
    return this.MakesArray
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Make' : 'Edit Make'
    },
    formSubTitle(){
      return this.editedIndex === -1 ? 'Fill the form below and add a new Make' : 'Edit the Make '+this.editedItem.Name
    },
    formSubTitleIcon(){
      return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'
    },
    formSubTitleIconColor(){
      return this.editedIndex === -1 ? 'green' : 'orange'
    },
  MakesSearched() {
    return this.MakesArray.filter(Make => {
      if(Make.Name){return Make.Name.toLowerCase().includes(this.search.toLowerCase())}
    }).filter(Make => {
      if(this.$route.params.id && this.RelatedObj || this.$route.params.id && this.RelatedParentObj){
        if(this.ParentFilterField && Make[this.ParentFilterField]){
          return Make[this.ParentFilterField].DocumentID === this.$route.params.id
        }          
      }
      else{
        return Make
      }
    })
    },
    MakesFilteredandSearched() {
    return this.MakesSearched
        .filter(Make => {
        if(this.Statusfilter){
        return Make.Status && Make.Status.ID === this.Statusfilter.ID
        }
        else {
        return Make
        }
        })
        .filter(Make => {
          if(this.Status_Reasonfilter){
          return Make.Status_Reason && Make.Status_Reason.ID === this.Status_Reasonfilter.ID
          }
          else {
          return Make
          }
          }).map(make => {
        return make
        }).map(make => {
        if(this.PageView && this.PageFilter){
          if(this.PageFilter.FieldType === 'Option Set'){
            if(make[this.PageFilter.FieldName]){
              this.PageFilter.Filter.map(filter => {
                if(filter.ID === make[this.PageFilter.FieldName].ID){
                  make.Render = true
                }
              })
            }
          }
        }
        else{
          make.Render = true
        }
        return make
      })
      .filter(make => {
        return make.Render === true
      })
    },
    
    HeaderNames() {
      return this.headers.map(header => {
        return header.value.split('.')[0]
      }).filter(header => {
        return header !== 'id'
      })
    },
    HeaderNamesSplit() {
      return this.HeaderNames.map(header => {
       let correctheader = header
        return correctheader
      })
    },
  
    },
  watch: {
      userLoggedIn (value) {
        //if (value !== null && value !== undefined) {
         // this.$router.push('/')
        //}
      },
      FinalDashboardArray (value) {
        if(value && value.length > 0){
          let vm = this
          if(typeof this.dashboardelementindex !== 'undefined'){
               
                let lookuparrays = [
                ]
                vm.$emit('updatearray',this.dashboardelementindex,this.FinalDashboardArray,lookuparrays)
              }
        }
      },
    },
  methods: {
    
    ActivateExportDialog(array,headers){
      this.ExportDataArray = array.map(item => {
        delete item.Children
        delete item.headers
        return item
      })
      this.ExportHeaders = Array.from(headers)
      this.exportdialog = true
    },
    closeExport(){
      this.exportdialog = false
      this.ExportData = []
      this.ExportHeaders = []
    },
    ExportData(){
     this.exportToCsv('download.csv',this.ExportDataArray)
    },
    ExportDataforReimport(){
    this.exportToCsv('download.csv',this.ExportDataArray,true)
  },
    exportToCsv(filename, exportdata,forreimport) {
  const headers = this.ExportHeaders.map(header => {
    return header.value.split('.')[0]
  })
  const headerrows = [headers]
  if(forreimport === true){
    headers.push('id')
    let idobj = { text: 'id', value: 'id'}
    this.ExportHeaders.push(idobj)
    headers.push('id')
    this.ExportHeaders.push(idobj)
  }
  console.log(this.ExportDataArray)
  console.log(this.ExportHeaders)
  const datarows = exportdata.map(row => {
    row.csvdata =  this.ExportHeaders.map(header => {
      
      let headervalue = ''
      let firstprop = header.value.split('.')[0]
      let secondprop = header.value.split('.')[1]
      
      if(secondprop){
        
        if(row[firstprop]){
          if(row[firstprop][secondprop]){   
            let thirdprop = row[firstprop][secondprop]
                         
          headervalue = row[firstprop][secondprop]
          }
        }
      }
      else{
        if(row[firstprop]){
          
        headervalue = row[firstprop]
        }
      }   
      if(header.text === 'Owner'){
        if(row.Owner){
          headervalue = row.Owner.Full_Name
        }
        
      }         
      return headervalue
    })
    return row
    }).map(row => {
      return row.csvdata
    })
   const rows = headerrows.concat(datarows)
  

  let csvContent = "data:text/csv;charset=utf-8," 
      + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "my_data.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
  },
      
  CheckRouting(){
    
      if(typeof this.$route.params.id !== 'undefined' && typeof this.dashboardelementindex === 'undefined'){
        //this.defaultItem[this.RelatedObj.identifier] = this.RelatedObj
            //this.editedItem = Object.assign({}, this.defaultItem)
            let queryfield = this.RelatedObj.identifier+'.DocumentID'
            
            if(typeof this.RelatedParentObj !== 'undefined'){
              //this.defaultItem[this.RelatedParentObj.identifier] = this.RelatedParentObj
              let queryfield = this.RelatedParentObj.identifier+'.DocumentID'
              this.ParentFilterField = this.RelatedParentObj.identifier
              this.CollectionRef = this.MakesQuery.where(queryfield,'==',this.RelatedParentObj.id)
              this.GetRequestingUser(true)
            }
            
            else{
              let queryfield = this.RelatedObj.identifier+'.DocumentID'
              this.ParentFilterField = this.RelatedObj.identifier
              this.CollectionRef = this.MakesQuery.where(queryfield,'==',this.RelatedObj.id)
              this.GetRequestingUser(true)
            }
      }
      else if(this.DashboardView){
        this.MakesArray = this.DashboardArray
      }
      else{
        
        this.GetRequestingUser()
      }
  },
  
  

  GetBusinessUnits(){
    db.collection('businessunits').onSnapshot(res => {

    const changes = res.docChanges();
    changes.forEach(change => {
      if (change.type === 'added') {
        this.BusinessUnitsArray.push({
          ...change.doc.data(),
          id: change.doc.id
        })
      }


    })
  })
  },

  closeImport(){
    this.importdialog = false
    this.ImportFile = null
    this.Import.Name = ''
  },

  saveImport() {
    this.ImportingProgressDialog = true
    setTimeout(() => {
    const totalprogresselement = document.getElementById('importprogressbar')
    totalprogresselement.setAttribute('max','100')
    totalprogresselement.setAttribute('value',1)
  }, 200);
    this.Importcount = 'Preparing Import'
    let importLocalURL = URL.createObjectURL(this.ImportFile)
    var reader = new FileReader();
    let test = reader.readAsText(this.ImportFile);
    let vm = this
    reader.addEventListener('load', function(e) {
          var text = e.target.result;
          vm.CSVConvertToJSON(text)
    });
  },


  CheckDelimiter(lines){
    let headertest = lines[0].split(";");
    if(headertest.length ===1){
      this.ConversionCSVtoJJSON(lines,',')
    }
    else{
      this.ConversionCSVtoJJSON(lines, ';')
    }
    console.log('headertest')
    console.log(headertest)
  },
  ConversionCSVtoJJSON(lines,delimiter){
    let dataonly = Object.assign([],lines)
    dataonly.splice(0,1)
    console.log(dataonly)
    this.CSVResult = []
    this.ImportHeaders=lines[0].split(delimiter);
    console.log('this.ImportHeaders')
    console.log(this.ImportHeaders)
    let finalindex = this.ImportHeaders.length-1
  let finalheader = this.ImportHeaders[finalindex]
  if(finalheader.includes('id')){
    this.Reimport = true
    confirm('We picked up this is a Reimport. Shall you continue?') && this.ProceedReimport(lines,delimiter)
  }
  else{
    for(var i=1;i-1+2<lines.length;i++){

    var obj = {};
    var currentline=lines[i].split(delimiter);
    
    for(var j=0;j<this.ImportHeaders.length;j++){
      obj[this.ImportHeaders[j]] = currentline[j];
    }
    let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
    this.CSVResult.push(obj);
    console.log("this.CSVResult length is "+this.CSVResult.length)


  }
  this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
    this.CSVResult.map((res,i) => {
      
      this.UpdateFiresStore(res,i)
      
    })
  }
  },
  ProceedReimport(lines,delimiter){
  for(var i=1;i-1+2<lines.length;i++){
      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
      this.CSVResult.push(obj);
      console.log("this.CSVResult length is "+this.CSVResult.length)
    }
    this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
      this.CSVResult.map((res,i) => {
          this.UpdateFiresStoreReimport(res,i)
      })
},
  CSVConvertToJSON(text){
   
    this.NewImportsArray = []
    this.PartialFailuresArray = []
    
    let lines = text.split("\n")
    this.CheckDelimiter(lines)
    
  },
  UpdateFiresStoreReimport(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let NameQuery = []
                        let NameStringArray = SingleResult.Name.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(NameStringArray.length,NameStringArray)
                          for (p = 0; p < NameStringArray.length; p++) {
                              buildup = buildup+ NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          NameQuery = lowertext.split(',')  
                          NameQuery.length = NameQuery.length-1
                  
    let ImportMake = {
      Name: SingleResult.Name.trim(),
      NameQuery: NameQuery,
      Modified_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date(), 
      ImportedRecord: true
      }
      
        ImportMake.id = SingleResult.id
      
      
      let FailureObject = Object.assign({},ImportMake)
      FailureObject.Failures = []
      if(SingleResult.Status !== ''){
        let Options = [
  {
    "ID": 1000001,
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true,
    "Name": "Active",
    "Level": {
      "children": [
        {
          "FieldName": "Status_Reason",
          "children": [],
          "id": 2,
          "name": "Status Reason"
        }
      ],
      "id": 1,
      "FieldName": "Status",
      "name": "Status"
    },
    "Options": [
      {
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "Level": {
          "name": "Status Reason",
          "children": [],
          "id": 2,
          "FieldName": "Status_Reason"
        },
        "ID": 1000003
      }
    ]
  },
  {
    "Options": [
      {
        "LevelFieldName": "Status_Reason",
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        },
        "Name": "Closed",
        "ID": 1000004
      }
    ],
    "ID": 1000002,
    "Name": "InActive",
    "Level": {
      "children": [
        {
          "id": 2,
          "FieldName": "Status_Reason",
          "name": "Status Reason",
          "children": []
        }
      ],
      "FieldName": "Status",
      "id": 1,
      "name": "Status"
    },
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status = 'Failed - No Matching Option Found'
          ImportMake.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let StatusObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportMake.Status = StatusObject
        }

      }
      
      if(ImportMake.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
      // db.collection('makes').add(ImportMake).then({
      // })
      db.collection('makes').doc(ImportMake.id).update({
      Name: ImportMake.Name,
      NameQuery: ImportMake.NameQuery,
      Status: ImportMake.Status,
      Modified_By: ImportMake.Modified_By,
      Modified_Byid: ImportMake.Modified_Byid,
      Modified_On: ImportMake.Modified_On, 
      ImportedRecord: true
        
      })
      
     
        let AllImportObj = this.NewImportsArray.find(imp => imp.Name == ImportMake.Name)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = ImportMake.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Name == ImportMake.Name)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = ImportMake.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Name == ImportMake.Name)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = ImportMake.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.UserRecord
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'Makes', datasetrecordpath: 'Make', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      


  },
  UpdateFiresStore(SingleResult, Index){
    console.log("Index")
    console.log(Index)
    let ResultLength = this.CSVResult.length
    console.log("ResultLength")
    console.log(ResultLength)
    console.log("Index-1+2")
    console.log(Index-1+2)
    let OriginalImportObject = Object.assign({},SingleResult)                
                  
                      let NameQuery = []
                        let NameStringArray = SingleResult.Name.trim()
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(NameStringArray.length,NameStringArray)
                          for (p = 0; p < NameStringArray.length; p++) {
                              buildup = buildup+ NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          NameQuery = lowertext.split(',')  
                          NameQuery.length = NameQuery.length-1
                  
    let ImportMake = {
      Name: SingleResult.Name.trim(),
      NameQuery: NameQuery,
      Created_By: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
      Created_Byid: this.UserRecord.id,
      Created_On: new Date(),        
      Status: {ID: 1000001, Name: 'Active'},
      Status_Reason: {ID: 1000001, Name: 'Active'},
      ImportedRecord: true
      }
      
   
      
      let FailureObject = Object.assign({},ImportMake)
      FailureObject.Failures = []
      if(SingleResult.Status !== ''){
        let Options = [
  {
    "ID": 1000001,
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true,
    "Name": "Active",
    "Level": {
      "children": [
        {
          "FieldName": "Status_Reason",
          "children": [],
          "id": 2,
          "name": "Status Reason"
        }
      ],
      "id": 1,
      "FieldName": "Status",
      "name": "Status"
    },
    "Options": [
      {
        "LevelFieldName": "Status_Reason",
        "Name": "Open",
        "Level": {
          "name": "Status Reason",
          "children": [],
          "id": 2,
          "FieldName": "Status_Reason"
        },
        "ID": 1000003
      }
    ]
  },
  {
    "Options": [
      {
        "LevelFieldName": "Status_Reason",
        "Level": {
          "id": 2,
          "FieldName": "Status_Reason",
          "children": [],
          "name": "Status Reason"
        },
        "Name": "Closed",
        "ID": 1000004
      }
    ],
    "ID": 1000002,
    "Name": "InActive",
    "Level": {
      "children": [
        {
          "id": 2,
          "FieldName": "Status_Reason",
          "name": "Status Reason",
          "children": []
        }
      ],
      "FieldName": "Status",
      "id": 1,
      "name": "Status"
    },
    "LevelFieldName": "Status",
    "HasDeepHierarchy": true
  }
]
        let GetObj = Options.find(option => option.Name == SingleResult.Status)
        if(typeof GetObj === 'undefined'){
          FailureObject.Status = 'Failed - No Matching Option Found'
          ImportMake.hasFailures = true
          let FailureInstance = {Type: 'No Matching Option', Name: 'Status'}
          FailureObject.Failures.push(FailureInstance)
        }
        else{
        let StatusObject = {ID: GetObj.ID,Name: GetObj.Name}
        ImportMake.Status = StatusObject
        }

      }
      

      if(ImportMake.hasFailures){
      this.PartialFailuresArray.push(FailureObject)

      }
      else{
        this.SuccessfulImportsArray.push(OriginalImportObject)
      }

      this.NewImportsArray.push(OriginalImportObject)
      // db.collection('makes').add(ImportMake).then({
      // })
      db.collection('makes').add(ImportMake).then(doc => {
        let AllImportObj = this.NewImportsArray.find(imp => imp.Name == ImportMake.Name)
        if(typeof AllImportObj !== 'undefined'){
        let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
        this.NewImportsArray[AllImportObjIndex].id = doc.id
        console.log(this.NewImportsArray[AllImportObjIndex])
        console.log(this.NewImportsArray)
        }
        let PartialImportObj = this.PartialFailuresArray.find(imp => imp.Name == ImportMake.Name)

        if(typeof PartialImportObj !== 'undefined'){
        let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
        this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
        console.log(this.PartialFailuresArray[PartialImportObjIndex])
        console.log(this.PartialFailuresArray)
        }
        let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.Name == ImportMake.Name)
        if(typeof SuccessImportObj !== 'undefined'){
        let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
        this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
        console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
        console.log(this.SuccessfulImportsArray)
        }
        let indexstring = Index.toString()
      let totallength = ResultLength.toString()
      let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
      this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('value',runningPercentage)
        if (Index-1+2 === ResultLength){
      console.log("NewImportsArray")
      console.log(this.NewImportsArray)
      console.log("PartialFailuresArray")
      console.log(this.PartialFailuresArray)

      let Created_On = new Date()
      let User = this.UserRecord
      let RefinedHeaders = this.ImportHeaders.map(header => {
        let obj = {text: header, value: header}
        return obj
      })
      console.log("this.NewImportsArray at final length")
      console.log(this.NewImportsArray)
      let DataImport = {name: this.Import.Name, dataset: 'Makes', datasetrecordpath: 'Make', createdon: Created_On, createdby: {id: User.id, Full_Name: User.Name+' '+User.Surname}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
      db.collection('dataimports').add(DataImport).then(doc => {
      var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
      var uploadTask = storageRef.put(this.ImportFile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          db.collection('dataimports').doc(doc.id).update({
                  csvfile: url
          })
          this.ImportFile = ''
              }),
              this.importresultsdialog = true
              this.importdialog = false
              this.Import.Name = ''
              this.ImportingProgressDialog = false
      })

      }
      })


  },
  CloseImportresultsdialog(){
    this.importresultsdialog = false
  },
  onImportfileSelected(event){
    this.ImportFile = event.target.files[0]
  },

  DownloadMakesImportTemplate(){
    let copyText = this.HeaderNamesSplit

    var csvfilename = 'Makes.csv'
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
    element.setAttribute('download', csvfilename)
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // }
  },
  ResetFilters() {
    this.Statusfilter = ''
    this.Status_Reasonfilter = ''
  },
  ResetSearch() {
    this.search = ''
  },
    
  BURolesCheck(RoleObject){
        let vm = this
        let CreatePermissionLevel = 0
        let GetPermissionLevel = 0
        let ListPermissionLevel = 0
        let UpdatePermissionLevel = 0
        let DeletePermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          let alldeltest = RoleObject.AlldeleteRoles.find(obj => obj == role)
          let unitdowndeltest = RoleObject.UnitDowndeleteRoles.find(obj => obj == role)
          let userunitdeltest = RoleObject.UserUnitdeleteRoles.find(obj => obj == role)
          let ownerdeltest = RoleObject.OwnerdeleteRoles.find(obj => obj == role)
          let alledittest = RoleObject.AllupdateRoles.find(obj => obj == role)
          let unitdownedittest = RoleObject.UnitDownupdateRoles.find(obj => obj == role)
          let userunitedittest = RoleObject.UserUnitupdateRoles.find(obj => obj == role)
          let owneredittest = RoleObject.OwnerupdateRoles.find(obj => obj == role)
          let allviewtest = RoleObject.AllgetRoles.find(obj => obj == role)
          let unitdownviewtest = RoleObject.UnitDowngetRoles.find(obj => obj == role)
          let userunitviewtest = RoleObject.UserUnitgetRoles.find(obj => obj == role)
          let ownerviewtest = RoleObject.OwnergetRoles.find(obj => obj == role)
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
          let allcreatetest = RoleObject.AllcreateRoles.find(obj => obj == role)
          let peercreatetest = RoleObject.UnitDowncreateRoles.find(obj => obj == role)
          let parentcreatetest = RoleObject.UserUnitcreateRoles.find(obj => obj == role)
          let childcreatetest = RoleObject.OwnercreateRoles.find(obj => obj == role)
          if(typeof alldeltest !== 'undefined' || vm.IsAdmin){
            DeletePermissionLevel = 4
          }
          else if(typeof unitdowndeltest !== 'undefined'){
            if(DeletePermissionLevel<3){
              DeletePermissionLevel = 3
            }
          }
          else if(typeof userunitdeltest !== 'undefined'){
            if(DeletePermissionLevel<2){
              DeletePermissionLevel = 2
            }
          }
          else if(typeof ownerdeltest !== 'undefined'){
            if(DeletePermissionLevel<1){
              DeletePermissionLevel = 1
            }
          }
          if(typeof alledittest !== 'undefined' || vm.IsAdmin){
            UpdatePermissionLevel = 4
          }
          else if(typeof unitdownedittest !== 'undefined'){
            if(UpdatePermissionLevel<3){
              UpdatePermissionLevel = 3
            }
          }
          else if(typeof userunitedittest !== 'undefined'){
            if(UpdatePermissionLevel<2){
              UpdatePermissionLevel = 2
            }
          }
          else if(typeof owneredittest !== 'undefined'){
            if(UpdatePermissionLevel<1){
              UpdatePermissionLevel = 1
            }
          }
          if(typeof allviewtest !== 'undefined' || vm.IsAdmin){
            GetPermissionLevel = 4
          }
          else if(typeof unitdownviewtest !== 'undefined'){
             if(GetPermissionLevel<3){
              GetPermissionLevel = 3
            }
          }
          else if(typeof userunitviewtest !== 'undefined'){
            if(GetPermissionLevel<2){
              GetPermissionLevel = 2
            }
          }
          else if(typeof ownerviewtest !== 'undefined'){
            if(GetPermissionLevel<1){
              GetPermissionLevel = 1
            }
          }
          if(typeof alltest !== 'undefined' || vm.IsAdmin){
            ListPermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(ListPermissionLevel<3){
              ListPermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(ListPermissionLevel<2){
              ListPermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(ListPermissionLevel<1){
              ListPermissionLevel = 1
            }
          }
          if(typeof allcreatetest !== 'undefined' || vm.IsAdmin){
            CreatePermissionLevel = 4
          }
          else if(typeof peercreatetest !== 'undefined'){
            if(CreatePermissionLevel<3){
              CreatePermissionLevel = 3
            }
          }
          else if(typeof parentcreatetest !== 'undefined'){
            if(CreatePermissionLevel<2){
              CreatePermissionLevel = 2
            }
          }
          else if(typeof childcreatetest !== 'undefined'){
            if(CreatePermissionLevel<1){
              CreatePermissionLevel = 1
            }
          }
            if(i-1+2 === Roleslength){
              let PermissionLevel = {
                CreatePermissionLevel: CreatePermissionLevel,
                GetPermissionLevel: GetPermissionLevel,
                ListPermissionLevel: ListPermissionLevel,
                UpdatePermissionLevel: UpdatePermissionLevel,
                DeletePermissionLevel: DeletePermissionLevel

              }
              resolve(PermissionLevel)
            }
          })
        })
      },
      BUListRolesCheck(RoleObject){
        let vm = this
        let ListPermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
           
          if(typeof alltest !== 'undefined' || vm.IsAdmin){
            ListPermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(ListPermissionLevel<3){
              ListPermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(ListPermissionLevel<2){
              ListPermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(ListPermissionLevel<1){
              ListPermissionLevel = 1
            }          
          }
            if(i-1+2 === Roleslength){
              let PermissionLevel = {
                ListPermissionLevel: ListPermissionLevel,
              }
              resolve(PermissionLevel)
            }
          })
        })
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      
      GetMakes() {
      let vm = this
      return new Promise(function(resolve, reject) {
        vm.CollectionRef.onSnapshot(res => {
          let listlength = res.docs.length
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.MakesArray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              if(vm.MakesArray.length === listlength){
                  resolve('done calling MakesArray')
              }
            })
         })
        })
      },
        GetRequestingUser(filteredcall){
      let vm = this
      if(this.userLoggedIn){
        this.UserRecord = this.userLoggedIn
        if(filteredcall === true){
          this.MakesCall(this.CollectionRef)
          }   
        else if(vm.MakesStore.length>0){
          
          
          vm.MakesArray = vm.MakesStore
          
          
        }
        else if (this.MakesQuery){
          let payload = {                
                      query: vm.$store.state.MakesQuery,
                      arraymutation: 'setMakesArray',
                      getter: 'getMakesArray',
                      arrayname: 'MakesArray'
                    }
                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                          console.log(vm.MakesStore)
                          vm.MakesArray = vm.MakesStore
                          })
          
          }
          else {
        vm.RoutetoLoginPage()
          }
       
      }
      else {
        vm.RoutetoLoginPage()
          }
          
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
    
  MakesCall(query){
    let vm = this
    query.onSnapshot(res => {
      let listlength = res.docs.length
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MakesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
        if(this.MakesArray.length === listlength && this.DocTableCall){
          vm.$emit('ReferencedDocsArrayPush',vm.MakesArray,'MakesArray',vm.headers)
        }          
      })
    })
  },
  
  
    

    ViewItem (item) {
      if(this.DashboardView || this.IntranetView){
        let routeData = this.$router.resolve({name: 'make',params: {id: item.id}})
        window.open(routeData.href, '_blank')
      }
      
      else{
        this.$router.push('/Make/'+item.id)
      }
      
    },

    deleteItem (item) {
      const index = this.MakesArray.indexOf(item)
      confirm('Are you sure you want to delete this item?') && db.collection('makes').doc(item.id).delete() && this.MakesArray.splice(index, 1)
    },

    editItem (item) {
      this.editedIndex = this.MakesArray.indexOf(item)
      this.editedItem = Object.assign({}, item)
      
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.UndefinedMandatoryFields.map(prop => {
      
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
            
          })
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },  
    

    save(file) {
      this.UndefinedMandatoryFields = []
    const EditedMake = {
  Name: this.editedItem.Name,
  Status: this.editedItem.Status,
      }
      if (this.editedIndex > -1) {
       
    for(var prop in EditedMake) {
        if (EditedMake.hasOwnProperty(prop)) {
          if(typeof EditedMake[prop] === 'undefined'){
            this.UndefinedMandatoryFields.push(prop)
          }
        else {
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
          }
          }
    
        else{
          console.log('something is amook')
        }
      }
      console.log(this.UndefinedMandatoryFields)
      if(this.UndefinedMandatoryFields.length === 0){
    db.collection('makes').doc(this.editedItem.id).update({
      Name: EditedMake.Name,
      
      Status: EditedMake.Status,
      Status_Reason: EditedMake.Status_Reason,
      
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date()
            });
            Object.assign(this.MakesArray[this.editedIndex], this.editedItem)
            this.close()
            this.EditedMakesnackbar = true
      }
      //here we loop for the optional fields and add them as we go
          
      }
      
       else {
        const NewMake = {
    Name: EditedMake.Name,
      
    Status: EditedMake.Status,
              
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
      Modified_Byid: this.UserRecord.id,
      Modified_On: new Date()
        }
        
        for(var prop in NewMake) {
        if (NewMake.hasOwnProperty(prop)) {
          if(typeof NewMake[prop] === 'undefined' || NewMake[prop] === ''){
            this.UndefinedMandatoryFields.push(prop)
            console.log(prop)
            console.log(NewMake[prop])
          }
        else {
          let element = document.getElementById(prop)
            if(element){  
              var x = element.hasAttribute("class")
              if(x){        
                element.removeAttribute("class")
                }
              }
          }
          }
    
        else{
          console.log('something is amook')
        }
      }
      
        
          console.log(this.UndefinedMandatoryFields)
      if(this.UndefinedMandatoryFields.length === 0){
        NewMake.Created_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}
        NewMake.Created_Byid = this.UserRecord.id,          
        NewMake.Status = this.editedItem.Status,
        NewMake.Status_Reason = this.editedItem.Status_Reason,
        NewMake.Created_On = new Date(),
        NewMake.Modified_By = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        NewMake.Modified_Byid = this.UserRecord.id,
        NewMake.Modified_On = new Date()
        NewMake.Name = this.editedItem.Name
             
                        let NameQuery = []
                        let NameStringArray = this.editedItem.Name.split('')
                        var lowertext = "";                
                        var p;
                          let buildup = ''
                        console.log(NameStringArray.length,NameStringArray)
                          for (p = 0; p < NameStringArray.length; p++) {
                              buildup = buildup+ NameStringArray[p]
                                lowertext += buildup.toLowerCase() + ",";
                                }                   
                          NameQuery = lowertext.split(',')  
                          NameQuery.length = NameQuery.length-1
                       // db.collection('makes').doc(doc.id).update({
                          NewMake.NameQuery = NameQuery,

                         // })
                      
        db.collection('makes').add(NewMake).then(doc => {
          let snackbarcontent = {
          snackbartimeout: 10000,
          snackbartext: 'Added New Make - '+NewMake.Name,
          snackbartop: true,
          snackbarpath: '/Make/'+doc.id
        }
        this.$emit('ActivateSnackbar',true,snackbarcontent)
                                
                    
                
                                
                  this.close()
        })
        
      }
        else{
      alert('you need to capture the values in '+this.UndefinedMandatoryFields)
      this.UndefinedMandatoryFields.map(field => {
        console.log('getting element by id '+field)
        let element = document.getElementById(field)
        
        element.setAttribute("class", "incompletedmandatory")
        console.log('here is element')
        console.log(element)
      })
    }
        
      }
      
    },
  
  }
}

</script>

 <style>
  
    
</style>
<style scoped>
.v-data-table{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:#757575;
}
</style>

            
        